import { Route } from '@angular/router';

export const contractDetailsRoute: Route = {
    loadComponent: () => import('./contract-details.component'),
    children: [
        { path: '', redirectTo: 'details', pathMatch: 'full' },
        { path: 'active-trip', loadComponent: () => import('./active-trip-tab/active-trip-tab.component') },
        { path: 'details', loadComponent: () => import('./contract-details-tab/contract-details-tab.component') },
    ],
};
