import { Routes } from '@angular/router';
import { contractDetailsRoute } from './contract-details/contract-details.route';

export const routes: Routes = [
    { path: '', redirectTo: '/contracts', pathMatch: 'full' },

    { path: 'login', loadComponent: () => import('./login/login.component') },
    { path: 'contracts', loadComponent: () => import('./contracts/contracts.component') },
    {
        path: 'contracts/:productCategory/:contractId',
        ...contractDetailsRoute,
    },
];
